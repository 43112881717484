import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const CourseItem = ({ item, uid }) => (
  <Link className="display-block col-lg-4 col-md-6 course-item" to={`/kursy/${uid}`}>
    <div className="course">
      <div className="course-image">{item.course_image.localFile && <img src={item.course_image.localFile.childImageSharp ? item.course_image.localFile.childImageSharp.fluid.src : ''} alt={item.title} />}
        <div className="overlay flex-column d-flex align-items-center justify-content-center">
          <div className="instructor-avatar">
            {item.teacher_image.localFile &&
            <img
              src={item.teacher_image.localFile.childImageSharp ? item.teacher_image.localFile.childImageSharp.fixed.src : ''}
              alt={item.teacher}
              className="img-fluid"
            />
            }
          </div>
          <div className="instructor-name"><strong>{item.teacher}</strong></div>
          <span className="watch-btn"><i className="fa fa-eye" />Pokaż szczegóły</span>
        </div>
      </div>
      <div className="course-header pb-1">
        <h3 className="h5">{item.title}</h3>
        <div className="d-flex align-items-center justify-content-between">
          <div className="category"><i className="fa fa-tags"></i>{item.category}</div>
          <div className="price"><strong>{item.price} <small>zł</small></strong></div>
        </div>
      </div>
      <div className="course-body">
        <p>{item.description}</p>
      </div>
      <hr />
      <div className="course-footer d-flex align-items-center justify-content-between">
        <div className="comments" title={item.level_title} ><i className="icon-chat" /><span>{item.level_value}</span></div>
        <div className="duration"  title={item.duration_title} ><i className="icon-clock-1" /><span>{item.time}</span></div>
      </div>
    </div>
  </Link>
)

CourseItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default CourseItem
