/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'
import CourseItem from '../components/CourseItem'
import Img from 'gatsby-image'

const Courses = ({ data: { coursePage, courses }, location}) => {

  const { data: coursesPageData } = coursePage || {}

  const courseItems = courses.edges

  const {
    page_title,
    description,
  } = coursesPageData

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={page_title}
        desc={description.text}
      />
      {/* Hero Section */}
      <section className="hero hero-page">
        {coursesPageData.top.localFile && <Img fluid={coursesPageData.top.localFile.childImageSharp.fluid} className="hero-banner" />}
        {!coursesPageData.top.localFile &&
        <div style={{background: "url(/img/staff-banner.jpg)"}} className="hero-banner"></div>
        }
        <div className="container">
          <div className="col-xl-8 text-content">
            <BreadCrumb path={[{ label: 'Kursy', to: `/kursy` }]} />
            <h1>{page_title}</h1>
            <div className="row" >
              <p className="col-lg-8" dangerouslySetInnerHTML={{ __html: description.html }} />
            </div>
          </div>
        </div>
      </section>
      <section className="courses-listing bg-gray pt-0">
        <br />
        <div className="container">
          <div className="row">
            {courseItems.map((item) => <CourseItem key={item.node.id} uid={item.node.uid} item={item.node.data} />)}
          </div>
        </div>
      </section>
    </>
  )
}

export default Courses

export const pageQuery = graphql`
  query CoursesQuery {
    coursePage: prismicCourses {
      data {
        page_title
        top {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1900, quality: 90) {
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
        description {
          html
          text
        }
        seo_description
      }
    }
    courses: allPrismicCourse {
      edges {
          node {
              id
              uid
              data {
                  title
                  category
                  teacher
                  description
                  price
                  duration_title
                  time
                  level_title
                  level_value
                  teacher_image {
                      localFile {
                          childImageSharp {
                              fixed(width: 150) {
                                  src
                              }
                          }
                      }
                  }
                  course_image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 1200, quality: 90) {
                                  tracedSVG
                                  aspectRatio
                                  src
                                  srcSet
                                  srcWebp
                                  srcSetWebp
                                  sizes
                                  originalImg
                                  originalName
                                  presentationWidth
                                  presentationHeight
                              }
                          }
                      }
                  }
              }
          }
      }
    }
  }
`
